
import React, {useState} from "react"

import Layout from "../../components/layout";
import WebappsForm from "../../components/webapps/form"


const formatTools = require("../../../lib/formatTools")
const negosyonowAPI = require("../../../lib/requestNegosyownow")
const webappsAPI = require("../../../lib/requestWebapps");

const ENTITYID="onsbooking"


const searchFields = [
	{"label":"Order Date", "dbfield": "onsorderform_datetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"Order #", "dbfield": "onsorderform_code", "type": "text", "filtertype": "textbox"},
	{"label":"Seller", "dbfield": "onsbranch_name", "type": "text", "filtertype": "textbox"},
	{"label":"Fulfillment Method", "dbfield": "onsorderformfulfillment_name", "type": "text", "filtertype": "textbox"},
	{"label":"Location/Provider", "dbfield": "nnpickup_name", "type": "text", "filtertype": "textbox"},
	{"label":"Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "textbox"},
	//{"label":"Order Status", "dbfield": "onsbookingstatus_name", "type": "text", "filtertype": "textbox"},
	{"label":"Delivery/Availability Date", "dbfield": "onsbooking_targetdeliverydate", "type": "datetime", "filtertype": "datetime"},
	{"label":"Amount Due", "dbfield": "onsbooking_availableamount", "type": "currency", "filtertype": "currency"},
];


const formFields = [
	[
		{"label":"Order Date", "field": "onsbooking_datetime", "value": "", "input": "datetime", "mode": "readonly"},
		{"label":"Order #", "field": "onsorderform_code", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Seller", "field": "onsbranch_name", "value": "", "input": "picker", "mode": "readonly"},

		{"label":"Customer", "field": "onscustomer_name", "value": "", "input": "picker", "mode": "readonly"},

		{"label":"Last Updated", "field": "onsbooking_updated", "value": "", "input": "datetime", "mode": "readonly"},

		{"label":"Fulfillment Method", "field": "onsorderformfulfillment_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Location/Provider", "field": "nnpickup_name", "value":"", "input": "picker", "mode": "readonly"},

		{"label":"Delivery Address", "field": "onsaddress_name", "value": "", "input": "picker", "mode": "readonly"},

		{"label":"Delivery/Availability Date", "field": "onsbooking_targetdeliverydate", "value":"", "input": "date", "mode": "readonly"},

		{"label":"Order Status", "field": "onsbookingstatus_id", "value": "", "input": "combo", "mode": "required", "options": [
			//{"value":1,"display":"Ordered"},
			{"value":4,"display":"Scheduled/Rescheduled"},
			{"value":9,"display":"For scheduling"},
			{"value":6,"display":"For re-scheduling"},
			{"value":7,"display":"Accepted/For dispatching"},
			//{"value":8,"display":"Packed"},
			//{"value":2,"display":"Dispatched"},
			//{"value":3,"display":"Completed"},
			//{"value":5,"display":"Cancelled"}
		]},

		{"label":"Reason/Details", "field": "onsbooking_locationnotes", "value":"", "input": "textarea", "mode": "normal"},
//
/*
		{"label":"Last Location", "field": "nnpickupalias_name", "value":"", "input": "picker", "mode": "readonly"},
		{"label":"Package Updated", "field": "onsbooking_locationdate", "value":"", "input": "datetime", "mode": "readonly"}
*/
	]
];

const defaultSubformFields = [
	{
		"subformid": "item",
		"label": "Items",
		"table": "onsbookingitem",
		"sort": "onsbookingitem_id",
		"mobilerowtitlefieldidx":[0,4],
		"minrow": 0,
		"maxrow": 0,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Item",
				"field": "onsproduct_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "readonly"
			},
/*			{
				"label": "Active",
				"field": "onsbookingitem_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "required"
			},
*/
			{
				"label": "Price/Unit",
				"field": "#onsproduct_price",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Order QTY",
				"field": "onsbookingitem_qty",
				"subtotalid": "orderpcs",
				"destfield": "",
				"value": "",
				"input": "integer",
				"mode": "readonly"
			},
			{
				"label": "Available QTY",
				"field": "onsbookingitem_pickqty",
				"subtotalid": "availableorderpcs",
				"value": "",
				"input": "integer",
				"mode": "readonly"
			},
			{
				"label": "Subtotal",
				"field": "onsbookingitem_pickqty*onsproduct_price*onsbookingitem_active",
				"subtotalid": "availableorderamount",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
			{
				"label": "Notes",
				"field": "onsbookingitem_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			},
		],
		"footerlist": [
			{
				"label": "Fees",
				"field": "onsbooking_fees",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Total",
				"field": "onsbooking_fees+availableorderamount",
				"subtotalid": "",
				"value": "",
				"destfield": "onsbooking_availableamount",
				"input": "currency",
				"mode": "readonly"
			},
		]
	}
];


const PackageCustomerschedulePage = ({location, userSearchParam}) => {
	const [token, setToken] = useState("");
	const [userinfo, setUserinfo] = useState({});
	const [pagetitle, setPagetitle] = useState("");

	const [onsorderformid, setOnsorderformid] = useState(0);
	const [onsorderformfulfillmentid, setoOsorderformfulfillmentid] = useState(0);
	const [bookingstatusid, setBookingstatusid] = useState(0);

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var onsbookingstatus_id=bookingstatusid;
		//var pkid = 0;
		var result = "";
		//var customparam = {};
		const updatetimestr = formatTools.getDateStr();
		var customparam = JSON.parse(JSON.stringify(params));
		var tmpnotes = "";
/*
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
*/
		if (params.hasOwnProperty("onsbookingstatus_id")) {
			if (params.onsbookingstatus_id) {
				onsbookingstatus_id =  parseInt(params.onsbookingstatus_id, 10);
			}
		}
		if (params.hasOwnProperty("onsbooking_locationnotes")) {
			if (params.onsbooking_locationnotes) {
				if (params.onsbooking_locationnotes.length > 0) {
					tmpnotes = "; "+params.onsbooking_locationnotes;
				}
			}
		}

		if (onsbookingstatus_id === 4) {
			callback({"status":"Error", "message":"Please update status"});
			return;
		} else if (onsbookingstatus_id === 7) {
			result = "Accepted";
		} else if (onsbookingstatus_id === 6) {
			result = "Request reschedule";
		} else if (onsbookingstatus_id === 5) {
			result = "Cancelled";
		}
		if (result.length > 0) {
			tmpnotes = result+tmpnotes;
		} else {
			// Avoid triggering order recompute
			tmpnotes = "Updated"+tmpnotes;
		}

		customparam.onsbooking_updated = updatetimestr;

		//callback({"status":"Error", "message":"Debug"}); return;

		webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				handlePackageHistory(response.documentid, token, updatetimestr, 7, 6 /* New */, tmpnotes, function(historyres) {
					triggerNotifications(response.documentid, onsorderformid, result, token, function() {
						callback(response);
					});
				}); // handlePackageHistory
			} else {
				callback(response);
			}
		});

	}

	function triggerNotifications(pkid, orderformid, result, token, callback)
	{
		if (result.length < 1) {
			callback();
			return;
		}

		negosyonowAPI.buildOrder(token, "order",{
			"notifysubject": result,
			"orderform": orderformid,
			"onsbooking": pkid,
			"action": "notifyorder"
		}).then(nnresponse => {
			callback();
		});
	}

	function handlePackageHistory(pkid, token, updatetimestr, nnpickup_id, packagestatus_id, notes, callback)
	{
		var action = "Others";

		webappsAPI.saveRecord("ONSBOOKINGLOCATIONHISTORY", {
				"onsbooking_id": pkid,
				"onsbookinglocationhistory_datetime": updatetimestr,
				"nnpickup_id": nnpickup_id,
				"onsbookinglocationhistory_action": action,
				"onsbookinglocationhistory_notes": notes,
				"onsbookinglocationhistory_user": "Customer/"+userinfo.name
			}, token).then(historyresponse => {
				callback({"status":"OK", "message":"Recorded"});
			}); // add history
	}

	function customSetFormData(newformdata)
	{
		var newbookingstatusid = 0;
		var neworderformid = 0;
		var newfulfillmentid = 0;


		if (newformdata.hasOwnProperty("onsorderform_id")) {
			if (newformdata.onsorderform_id) {
				neworderformid = newformdata.onsorderform_id;
			}
		}
		if (newformdata.hasOwnProperty("onsbookingstatus_id")) {
			if (newformdata.onsbookingstatus_id) {
				newbookingstatusid = newformdata.onsbookingstatus_id;
			}
		}
		if (newformdata.hasOwnProperty("onsorderformfulfillment_id")) {
			if (newformdata.onsorderformfulfillment_id) {
				newfulfillmentid = newformdata.onsorderformfulfillment_id;
			}
		}

		setBookingstatusid(newbookingstatusid);
		setOnsorderformid(neworderformid);
		setoOsorderformfulfillmentid(newfulfillmentid);
	}

	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {};
		if (inputlist[idx].field === "onsaddress_name") {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";

			if (onsorderformfulfillmentid !== 2) {
				tmpfield.value = "N/A (Not for Delivery)";
			}
			return tmpfield;
		} else if (bookingstatusid === 4) {
			if (inputlist[idx].field === "onsbookingstatus_id") {
				/*
					{"value":1,"display":"Ordered"},
					{"value":4,"display":"Allocated/Scheduled"},
					{"value":9,"display":"For scheduling (courier)"},
					{"value":6,"display":"For re-scheduling"},
					{"value":7,"display":"Accepted/For dispatching"},
					{"value":8,"display":"Packed"},
					{"value":2,"display":"Dispatched"},
					{"value":3,"display":"Completed"},
					{"value":5,"display":"Cancelled"}
				*/
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				if (bookingstatusid === 4) {
					tmpfield.options = [
						{"value":4,"display":"Scheduled/Rescheduled"},
						{"value":7,"display":"Accepted"},
						{"value":6,"display":"For review/re-scheduling"},
						{"value":5,"display":"Cancel"},
					];
				} else {
					tmpfield.mode = "readonly";
				}
				return tmpfield;
			} else if (inputlist[idx].field === "onsbooking_locationnotes" && bookingstatusid === 4) {
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.value = "";
				return tmpfield;
			} else if (inputlist[idx].field === "onsaddress_name") {
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";

				if (onsorderformfulfillmentid !== 2) {
					tmpfield.value = "N/A (Not for Delivery)";
				}
				return tmpfield;
			}
		} else {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";
			return tmpfield;
		}

		return inputlist[idx];

	}

	function checkSubformEdited(subformobj)
	{
		var rowidx = 0;
		var colidx = 0;
		while (rowidx < subformobj.length) {
			colidx = 0;
			while (colidx < subformobj[rowidx].length) {
				if (subformobj[rowidx][colidx].modified === true) {
					return true;
				}
				colidx++;
			}
			rowidx++;
		}
		return false;
	}

	function customValidateValues(mainform, subformlist, footerformlist)
	{
		var newstatus = 0;
		var newnotes = "";
		var idx = 0;
		var editeddata = false;

		while (idx < mainform.length) {
			if (mainform[idx].field === "onsbookingstatus_id") {
				newstatus = parseInt(mainform[idx].value, 10);
			} else if (mainform[idx].field === "onsbooking_locationnotes") {
				newnotes = mainform[idx].value;
			}
			idx++;
		}
		idx = 0;
		while (idx < subformlist.length) {
			if (checkSubformEdited(subformlist[idx])) {
				editeddata = true;
				break;
			}
			idx++;
		}
		if (newstatus === 4) {
			return {"status":"Error", "message":"Please update the status"};
		} else if (newstatus !== 7) {
			// Not yet accepted (7) and not current status(4)
			if (newnotes.length < 1) {
				return {"status":"Error", "message":"Please provide reason/details"};
				/*
				if (newstatus !== 6) {
					// Not rescheduled
					return {"status":"Error", "message":"Please provide reason/details"};
				} else if (editeddata === false) {
					//
					return {"status":"Error", "message":"Please provide reason/details"};
				}
				*/
			}
		}
		return {"status":"OK"};
	}

	return <Layout fullPath={location.pathname}
			newUserHandler={(newtoken, newuserinfo)=>{setToken(newtoken); setUserinfo(newuserinfo)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={false}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["onsorderform_code","onsorderform_datetime"]}
				userSearchParam={{"orderby":"onsorderform_datetime desc", "joincond": "ONSORDERFORM.onsorderform_id=ONSBOOKING.onsorderform_id and ONSBRANCH.onsbranch_id=ONSBOOKING.onsbranch_id and ONSORDERFORMFULFILLMENT.onsorderformfulfillment_id=ONSBOOKING.onsorderformfulfillment_id and NNPICKUP.nnpickup_id=ONSBOOKING.nnpickup_id and ONSCUSTOMER.onscustomer_id=ONSBOOKING.onscustomer_id and onsbooking_targetdeliverydate<>'' and onsbooking_targetdeliverydate is not null and ONSBOOKING.onsbookingstatus_id=4"/*+" and ONSBOOKINGSTATUS.onsbookingstatus_id=ONSBOOKING.onsbookingstatus_id"*/}} // 4 Scheduled
				searchFields={searchFields}

				customSubmit={customSubmit}
				customSetFormData={customSetFormData}
				customEditFieldInfo={customEditFieldInfo}
				customValidateValues={customValidateValues}
				editFields={formFields}
				editSubFormFields={defaultSubformFields}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default PackageCustomerschedulePage;
